@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ct-series-a .ct-line {
  stroke: #52809c;
  stroke-width: 3px;
}

.ct-series-a .ct-point {
  stroke: #5995b8;
  stroke-width: 6px;
}